<template>
  <div>
    <form id="contactForm" @submit.prevent="handleSubmit">
      <div class="form-group">
        <input
          type="text"
          v-model="contact.firstName"
          id="firstName"
          name="firstName"
          class="form-control"
          placeholder="First Name"
          :class="{ 'is-invalid': submitted && $v.contact.firstName.$error }"
        />
        <div
          v-if="submitted && $v.contact.firstName.$error"
          class="help-block with-errors"
        >
          <span v-if="!$v.contact.firstName.required"
            >First name is required</span
          >
          <span v-if="!$v.contact.firstName.maxLength"
            >Message max length is 50</span
          >
        </div>
      </div>
      <div class="form-group">
        <input
          type="text"
          v-model="contact.lastName"
          id="lastName"
          name="lastName"
          class="form-control"
          placeholder="Last Name"
          :class="{ 'is-invalid': submitted && $v.contact.lastName.$error }"
        />
        <div
          v-if="submitted && $v.contact.lastName.$error"
          class="help-block with-errors"
        >
          <span v-if="!$v.contact.lastName.required"
            >Last name is required</span
          >
          <span v-if="!$v.contact.lastName.maxLength"
            >Message max length is 50</span
          >
        </div>
      </div>
      <div class="form-group">
        <input
          type="text"
          v-model="contact.email"
          id="email"
          name="email"
          class="form-control"
          placeholder="Email"
          :class="{ 'is-invalid': submitted && $v.contact.email.$error }"
        />
        <div
          v-if="submitted && $v.contact.email.$error"
          class="help-block with-errors"
        >
          <span v-if="!$v.contact.email.required">Email is required</span>
          <span v-if="!$v.contact.email.email">Email is invalid</span>
          <span v-if="!$v.contact.email.maxLength">Email max length is 80</span>
        </div>
      </div>
      <div class="form-group">
        <select
          id="programs"
          v-model="contact.programs"
          class="form-control"
          :class="{ 'is-invalid': submitted && $v.contact.programs.$error }"
          :disabled="selected_location"
        >
          <option value="" selected disabled>Donate To</option>
          <option value="PeaceJam">PeaceJam</option>
          <option value="Billion Acts">Billion Acts</option>
          <option value="Regional Affiliates">Regional Affiliates</option>
          <option value="Films">Films</option>
          <option value="NWI">NWI</option>
        </select>
        <div
          v-if="submitted && $v.contact.programs.$error"
          class="help-block with-errors"
        >
          <span v-if="!$v.contact.programs.required"
            >Please choose a category</span
          >
        </div>
      </div>
      <div class="form-group" v-if="contact.programs === 'Regional Affiliates'">
        <select
          :disabled="(locations_data === undefined && !locations_error) || selected_location"
          class="form-control"
          v-model="contact.location"
          :class="{ 'is-invalid': submitted && !contact.location }"
        >
        <template v-if="!selected_location">
          <option value="" selected disabled>Locations</option>
          <option
            v-if="locations_data"
            v-for="(item, index) in locations_data.data"
            :key="index"
            :value="item.title"
          >
            {{ item.title }}
          </option>
        </template>
        <option v-else>{{ selected_location.data[0].title }}</option>
        </select>
        <div
          v-if="submitted && !contact.location"
          class="help-block with-errors"
        >
          <span>Please choose a location</span>
        </div>
      </div>

      <div class="form-group" v-if="custom">
        <textarea
            name="note"
            class="form-control hight-unset"
            id="note"
            cols="30"
            rows="5"
            placeholder="Purpose of Donation (optional)"
            v-model="contact.note"
            :class="{ 'is-invalid': submitted && $v.contact.note.$error }">
        </textarea>

        <div v-if="submitted && $v.contact.note.$error"
             class="help-block with-errors">
          <span v-if="!$v.contact.note.maxLength">
            Message max length is 500
          </span>
        </div>
      </div>

      <div class="form-group" :class="{ 'custom-amount': !custom }">
        <input
          type="number"
          v-model="contact.amount"
          id="amount"
          name="amount"
          class="form-control"
          placeholder="Amount"
          :class="{ 'is-invalid': submitted && $v.contact.amount.$error }"
        />
        <div
          v-if="submitted && $v.contact.amount.$error"
          class="help-block with-errors"
        >
          <span v-if="!$v.contact.amount.required">Amount is required</span>
          <span v-if="!$v.contact.amount.minValue"
            >Amount min donation is $1</span
          >
        </div>
      </div>
      <div class="form-group">
        <div
          id="card-element"
          :class="{ 'is-invalid': submitted && cardErrors }"
          ref="cardElement"
          class="field"
        ></div>
        <div class="help-block with-errors" v-if="cardErrors">
          {{ cardErrors }}
        </div>
      </div>
      <button class="default-btn w-100" :disabled="loading">
        Donate
        <span v-if="this.contact.amount">${{ this.contact.amount }}</span>

        <span class="loading-btn" v-if="loading">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </span>

        <span class="payment-done" :class="{ 'show-success': paymentDone }">
          <i class="fa fa-check"></i>
        </span>
      </button>
    </form>
  </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import { email, maxLength, required, minValue } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import api from "../services/api";
import useSWRV from "swrv";
var cardStyle = {
  base: {
    iconColor: "#666ee8",
    color: "#31325f",
    fontWeight: 400,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
    fontSmoothing: "antialiased",
    fontSize: "15px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#666ee8",
    },
  },
};
export default {
  name: "DonateForm",
  setup(props) {

    if (props.selected_location) {
      return {
        locations_data: null,
        locations_error: null,
      };
    }
    let { data: locations_data, error: locations_error } = useSWRV(
      () => `items/locations?status=published`,
      api.fetcher
    );

    return {
      locations_data,
      locations_error,
    };
  },
  data(props) {
    return {
      pulishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      submitted: false,
      contact: {
        firstName: "",
        lastName: "",
        email: "",
        amount: props.price || null,
        programs: '',
        location: '',
        note: '',
      },
      stripe: null,
      elements: null,
      clientSecret: '',
      card: null,
      cardErrors: '',
      loading: false,
      paymentDone: false,
    }
  },
  validations: {
    contact: {
      firstName: { required, maxLength: maxLength(50) },
      lastName: { required, maxLength: maxLength(50) },
      email: { required, email, maxLength: maxLength(80) },
      amount: { required, minValue: minValue(1) },
      programs: { required },
      note: { maxLength: maxLength(500) },
    },
  },
  props: {
    price: {
      type: [String, Number],
      default: "",
    },
    custom: {
      type: [Boolean],
      default: false,
    },
    selected_location: {
      type: [Object],
      default: undefined,
    },
  },
  async created() {
    this.contact.amount = this.price || null;
    this.stripe = await loadStripe(this.pulishableKey);
    this.elements = this.stripe.elements();
    this.card = this.elements.create('card', {
      hidePostalCode: true,
      style: cardStyle,
    });
    this.card.mount('#card-element');

    this.card.on('change', ({ error }) => {
      if (error) {
        this.cardErrors = error.message;
      } else {
        this.cardErrors = '';
      }
    });
  },
  async mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    async setClientSecret(amount) {
      let description  = this.contact.programs + (this.contact.location ? ` - ${this.contact.location}` : '')
      if(this.contact.note) {
        description = this.contact.note
      }
      let data = await api.paymentIntent(+amount, description)
      if (data !== 'error') {
        this.clientSecret = data.data.clientSecret;
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Payment failed',
          text: 'Something went wrong. Please try later',
          showConfirmButton: false,
          timer: 4000
        });
      }
    },
    async handleSubmit() {
      this.submitted = true;

      this.card.focus()

      // stop here if form is invalid
      this.cardErrors = this.$refs.cardElement.classList.contains('StripeElement--empty') ? 'Card is required' : ''

      if (this.contact.programs === 'Regional Affiliates' && !this.contact.location) {
        return
      }

      this.$v.$touch();
      if (this.$v.$invalid || this.cardErrors) {
        return;
      }
      this.submitted = false;
      this.loading = true;

      if (this.contact.programs !== 'Regional Affiliates') {
        this.contact.location = ''
      }

      await this.setClientSecret(this.contact.amount)

      if (!this.clientSecret) {
        this.submitted = false;
        this.loading = false
        return
      }

      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.contact.firstName + ' ' + this.contact.lastName,
              email: this.contact.email,
            },
          },
          receipt_email: this.contact.email
        })
        .then((result) => {
          this.loading = false;
          if (result.error) {
            let message = result.error.message ? result.error.message : 'A processing error occurred.'
            Swal.fire({
              icon: 'error',
              title: message,
              showConfirmButton: false,
              timer: 2000
            });
          } else if (result.paymentIntent) {
            this.paymentDone = true;
            setTimeout(() => {
              this.$router.push('/donate-success/' + window.btoa(this.contact.firstName))
            }, 1000)
          }
        }).catch(error => {
          this.loading = false;
          let message = error.error.message ? error.error.message : 'A processing error occurred.'
          Swal.fire({
            icon: 'error',
            title: message,
            showConfirmButton: false,
            timer: 2000
          });
      })
    }
  },
  watch: {
    locations_data: {
      handler() {
        this.locations_data.data.sort((a, b) => {
          if(a.title < b.title) { return -1; }
          if(a.title > b.title) { return 1; }
          return 0;
        })
      }
    },
    selected_location: {
      immediate: true,
      handler() {
        if (this.selected_location && this.selected_location.data && this.selected_location.data.length > 0) {
          this.contact = {
            ...this.contact,
            programs: "Regional Affiliates",
            location: this.selected_location.data[0].title
          };
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.form-group {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  .help-block.with-errors {
    position: absolute;
    bottom: -19px;
    font-size: 13px;
    color: #dc3545;
  }
}
.default-btn {
  text-transform: capitalize;
  font-weight: 600;
  background: linear-gradient(
          45deg
      , #786CF0, #8e84f6);
  border: none;
  transition: 1s ease-out;

  &:disabled {
    background: #7c6ff1ad;
  }

  .loading-btn {
    position: absolute;
    top: 50%;
    right: 11px;
    transform: translateY(-50%);

    .spinner-border {
      width: 20px;
      height: 20px;
    }
  }

  .payment-done {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0fdbaf;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 26px;
    }

    &.show-success {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover {
    color: #fff;
    opacity: 0.8;
  }
}
#card-element {
  border-radius: 0;
  height: 50px;
  padding: 15px 15px 10px 15px;
  font-size: 14px;
  border: 1px solid #ced4da;

  &.is-invalid, &.is-invalid.StripeElement--invalid, &.is-invalid.StripeElement--empty {
    border-color: #dc3545 !important;
  }
}
.custom-amount {
  position: fixed;
  top: -10000px;
  left: -100000px;
  opacity: 0;
}
</style>
